import { AvailableCountriesT } from 'types'

export const COUNTRIES: AvailableCountriesT[] = [
  'DK',
  'SE',
  'NO',
  'UK',
  'FI',
  'DE',
]

export const userAllowedToAddNewSettings = [
  'tb@risika.com',
  'shk@risika.com',
  'mit@risika.com',
  'lcw@risika.com',
  'chc@risika.com',
]
